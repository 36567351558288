/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/05/27 15:54
 * @update 2019/05/27 15:54
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w19f1020Actions from "../../actions/w19/w19f1020/w19f1020-actions";
import * as userActions from "../../actions/user-actions";
import Config from '../../config';
import {Column} from 'devextreme-react/data-grid';
import {Col, Form, FormGroup, Row,} from 'react-bootstrap';
import EventTracking from "../common/tracking/event";
import GridContainer from "../common/grid-container/grid-container";
import {browserHistory} from "react-router";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import {Input} from "reactstrap";
import {DateBox} from "devextreme-react";
import Combo from "../common/dropdown/combo";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import moment from "moment";
import 'moment/locale/vi'
import DropDownCustomer from "../common/dropdown/dropdown-customer";
import DropDownSaleGroup from '../common/dropdown/dropdown-sale-group';

moment.locale('vi');

class W19F1020Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            taskDateForm: '',
            taskDateTo: '',
            iPermission: 0,
            statusID: "",
            typeTime: "",
            search: "",
            storeFilter: false,
            groupSalesID: ''
        };
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 10
        };
        this.companyID = '';
        this.salesPersonID = '';
        this.mode = '';
        this.rowData = ''
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        Config.getStoreState(this, () => {
            this.loadGrid();
        });
    };

    formatDate = (date) => {
        if (date) {
            return moment(date).format('MM/DD/YYYY');
        } else {
            return '';
        }
    };

    onChangeDropDown = (isState, stateName, value) => {
        if (isState) {
            this.setState({
                [stateName]: value
            }, () => this.loadGrid())
        } else {
            this[stateName] = value;
            this.loadGrid();
        }
    };

    loadGrid() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '84';
        const divisionID = Config.CompanyID() || '';

        const params = {
            "UserID": uName,
            "Language": uLanguage,
            "DivisionID": divisionID,
            "StrSearch": this.state.search,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "VoucherNum": this.state.voucherNumID,
            "GroupSalesID": this.state.groupSalesID,
            "SalesPersonID": this.state.salesPersonID,
            "CompanyID": this.companyID,
            "CustomerID": "",
            "Status": this.state.statusID,
            "DateFrom": this.formatDate(this.state.taskDateForm),
            "DateTo": this.formatDate(this.state.taskDateTo),
        };
        this.props.w19f1020Actions.loadDataW19F1020(params, (error) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
        })
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.setState({
            search: text
        }, () => {
            this.loadGrid();
        });
    }

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1021", (iPer) => {
            this.setState({iPermission: iPer});
        });
    }

    renderVoucherNum(data) {
        if (!data) return null;
        const name = data && data.value ? data.value : '';
        /* eslint-disable */
        return (
            <div><a onClick={() => this.onDetailVoucher(data.data)}>{name}</a></div>
        );
        /* eslint-disable */
    }

    onDetailVoucher = (e) => {
        if (e && e.QuotationID) {
            Config.setStoreState(this, this.state);
            browserHistory.push({
                pathname: Config.getRootPath() + 'W19F1021',
                state: {QuotationID: e.QuotationID, TranTypeID: e.TranTypeID, ScreenID: 'W19F1020'}
            });
        }
    };

    renderCompanyNameU(data) {
        if (!data) return null;
        const name = data && data.value ? data.value : '';

        /* eslint-disable */
        return (
            <div><a onClick={() => this.onDetailCompanyName(data.data)}>{name}</a></div>
        );
        /* eslint-enable */
    }

    onDetailCompanyName = (e) => {
        if (e && e.CustomerID) {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Customers', Permission: e.Permission, CompanyID: e.CustomerID}
            });
        }
    };

    onChangeComboSP(data) {
        if (data) {
            this.setState({
                salesPersonID: data.SalesPersonID,
            }, () => this.loadGrid());
        } else {
            this.setState({
                salesPersonID: '',
            }, () => this.loadGrid());
        }
    }

    onChangeComboStatus(data) {
        this.setState({
            statusID: data ? data.item : ''
        }, () => {
            this.loadGrid();
        });
    }

    changeVoucherNum = (e) => {
        document.getElementById('VoucherNum').value = Config.replaceSymbol(e.target.value);
        const value = Config.replaceSymbol(e.target.value);
        this.setState({
            voucherNumID: value
        });
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.loadGrid();
        }, 1000);
    };

    changeCustomer = (e) => {
        document.getElementById('CustomerID').value = Config.replaceSymbol(e.target.value);
        const value = Config.replaceSymbol(e.target.value);
        this.setState({
            customerID: value
        });
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.loadGrid();
        }, 1000);
    };

    onChangeTimeStart(data) {
        if (!this.state.storeFilter) {
            this.setState({
                typeTime: data.ID,
                taskDateForm: data.DateFrom,
                taskDateTo: data.DateTo
            }, () => {
                this.loadGrid()
            });
        }
    }

    render() {
        if (this.state.iPermission <= 0) return null;
        let dataStatus = [
            {
                item: "0100000000",
                itemName: Config.lang("CRM_Nhan_don_hang"),
            }
            , {
                item: "0110000000",
                itemName: Config.lang("CRM_Chap_nhan_don_hang"),
            }
            , {
                item: "0110001100",
                itemName: Config.lang("CRM_Dong"),
            }
            , {
                item: "0110000010",
                itemName: Config.lang("CRM_Huy"),
            }

        ];
        const {loadDataW19F1020} = this.props;
        const {voucherNumID, statusID, salesPersonID, resetComboPS, groupSalesID, typeTime, taskDateForm, taskDateTo, search} = this.state;

        return (
                <div className="page-container">
                    <EventTracking action={"FORM"} label={"W19F1020"}/>
                    <div className="grid-title">{Config.lang("CRM_Danh_sach_don_hang")}</div>
                    <Form horizontal={true}>
                        <FormGroup>
                            <Col lg={6} md={6}>
                                <Row>
                                    <Col lg={12} md={12}>
                                        <label className={'text-muted'}>{Config.lang("CRM_So_don_hang")}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12}>
                                        <Input id={'VoucherNum'}
                                               placeholder={Config.lang("CRM_So_don_hang")}
                                               autoComplete={'off'}
                                               value={voucherNumID}
                                               onPaste={(e) => {
                                                   document.getElementById('CustomerID').value = Config.replaceSymbol(e.target.value);
                                               }}
                                               onChange={(e) => {
                                                   this.changeVoucherNum(e);
                                               }}
                                               onKeyDown={(e) => {
                                                   if (e.which === 32) {
                                                       e.preventDefault();
                                                   }
                                               }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} md={6}>
                                <Row>
                                    <Col lg={12} md={12}>
                                        <label className={'text-muted'}>{Config.lang("CRM_Trang_thai")}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12}>
                                        <Combo
                                            showHeader={false}
                                            showClearButton={true}
                                            dataSource={dataStatus}
                                            placeholder={Config.lang("CRM_Trang_thai")}
                                            value={statusID}
                                            valueExpr="item"
                                            displayExpr="itemName"
                                            onActionWhenSelectChange={(data) => {
                                                this.onChangeComboStatus(data)
                                            }}
                                        >
                                            <Column dataField={'item'} caption="Mã" visible={false}
                                                    dataType={'string'}/>
                                            <Column dataField={'itemName'} caption="Tên" dataType={'string'}/>
                                        </Combo>
                                    </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={4} md={4}>
                                <Row>
                                    <Col lg={12} md={12}>
                                        <label className={'text-muted'}>{Config.lang("CRM_Nhom_kinh_doanh")}</label>
                                    </Col>
                                    <Col lg={12} md={12}>
                                        <DropDownSaleGroup showClearButton={true}
                                                           placeholder={Config.lang('CRM_Nhom_kinh_doanh')}
                                                           onChange={this.onChangeDropDown.bind(this, true, 'groupSalesID')}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={4} md={4}>
                                <Row>
                                    <Col lg={12} md={12}>
                                        <label className={'text-muted'}>{Config.lang("CRM_Nhan_vien_kinh_doanh")}</label>
                                    </Col>
                                    <Col lg={12} md={12}>
                                        <DropDownSalePerson showClearButton={true}
                                                            groupSalesID={groupSalesID}
                                                            placeholder={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                                                            value={salesPersonID}
                                                            reset={resetComboPS}
                                                            onChange={(e) => {
                                                                this.onChangeComboSP(e)
                                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={4} md={4}>
                                <Row>
                                    <Col lg={12} md={12}>
                                        <label className={'text-muted'}>{Config.lang("CRM_Khach_hang")}</label>
                                    </Col>
                                    <Col lg={12} md={12}>
                                        <DropDownCustomer placeHolder={Config.lang('CRM_Khach_hang')}
                                                          onChangeCustomer={this.onChangeDropDown.bind(this, false, 'companyID')}
                                                          groupSalesID={groupSalesID}/>

                                        {/*<Input id={'CustomerID'}*/}
                                        {/*       value={customerID}*/}
                                        {/*       onPaste={(e) => {*/}
                                        {/*           document.getElementById('CustomerID').value = Config.replaceSymbol(e.target.value);*/}
                                        {/*       }}*/}
                                        {/*       onChange={(e) => {*/}
                                        {/*           this.changeCustomer(e);*/}
                                        {/*       }}*/}
                                        {/*       onKeyDown={(e) => {*/}
                                        {/*           if (e.which === 32) {*/}
                                        {/*               e.preventDefault();*/}
                                        {/*           }*/}
                                        {/*       }}*/}
                                        {/*/>*/}
                                    </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={12}>
                                <Row>
                                    <Col lg={12}>
                                        <label className={'text-muted'}>{Config.lang("CRM_Thoi_gian")}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4} md={4}>
                                        <DropDownTypeTime
                                            listType={"W19F1020_TypeTime"}
                                            placeholder={""}
                                            value={typeTime}
                                            onChange={(data) => this.onChangeTimeStart(data)}
                                            onLoad={(data) => this.onChangeTimeStart(data)}
                                        >
                                        </DropDownTypeTime>
                                    </Col>
                                    <Col lg={4} md={4}>
                                        <DateBox
                                            width={"100%"}
                                            placeholder={Config.lang("CRM_Tu_ngay")}
                                            id={'CreateDateFrom'}
                                            openOnFieldClick={true}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            value={taskDateForm}
                                            type={'date'}
                                            pickerType={"calendar"}
                                            showAnalogClock={false}
                                            displayFormat={'dd/MM/y'}
                                            onValueChanged={(e) => {
                                                this.setState({
                                                    taskDateForm: e.value
                                                }, () => {
                                                    this.loadGrid()
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col lg={4} md={4}>
                                        <DateBox
                                            width={"100%"}
                                            placeholder={Config.lang("CRM_Den_ngay")}
                                            id={'CreateDateTo'}
                                            openOnFieldClick={true}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            value={taskDateTo}
                                            type={'date'}
                                            pickerType={"calendar"}
                                            showAnalogClock={false}
                                            displayFormat={'dd/MM/y'}
                                            onValueChanged={(e) => {
                                                this.setState({
                                                    taskDateTo: e.value
                                                }, () => {
                                                    this.loadGrid()
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>

                            </Col>
                        </FormGroup>
                        {loadDataW19F1020 && <GridContainer
                            dataSource={loadDataW19F1020}
                            // title={Config.lang("CRM_Danh_sach_don_hang")}
                            totalItems={loadDataW19F1020 ? loadDataW19F1020.length > 0 ? loadDataW19F1020[0].TotalRecord : 0 : 0}
                            listPerPage={[10, 20, 30, 50]}
                            itemPerPage={this.filter.limit}
                            isPer={this.state.iPermission}
                            valueSearch={search}
                            onSearch={(text) => {
                                this.onSearch(text)
                            }}
                            onChangePage={(page) => {
                                this.onChangePage(page)
                            }}
                            onChangePerPage={(per) => {
                                this.onChangePerPage(per)
                            }}
                        >
                            <Column
                                dataField={'Action'}
                                caption={'STT'}
                                alignment={'center'}
                                allowSorting={false}
                                showInColumnChooser={false}
                                cellRender={(e) => {
                                    return e.rowIndex + 1
                                }}
                                width={100}
                            />
                            <Column
                                dataField={'VoucherNum'}
                                caption={Config.lang("CRM_So_don_hang")}
                                cellRender={(data) => this.renderVoucherNum(data)}
                                allowSorting={true}
                                width={250}
                            />
                            <Column
                                dataField={'QuotationDate'}
                                alignment={'center'}
                                width={200}
                                dataType={'date'}
                                format={'dd-MM-yyyy'}
                                caption={Config.lang("CRM_Ngay_lap")}
                            />
                            <Column
                                dataField={'SalesPersonName'}
                                caption={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                                width={250}
                            />
                            <Column
                                dataField={'CustomerID'}
                                caption={Config.lang("CRM_Ma_khach_hang")}
                                width={200}/>
                            <Column
                                dataField={'CustomerNameU'}
                                cellRender={(data) => this.renderCompanyNameU(data)}
                                caption={Config.lang("CRM_Ten_khach_hang")}
                                width={300}/>
                            <Column
                                dataField={'StatusName'}
                                width={200}
                                caption={Config.lang("CRM_Trang_thai")}/>
                            <Column
                                dataField={'Quantity'}
                                width={130}
                                format={'#,##0'}
                                caption={Config.lang("So_luong")} />
                            <Column
                                dataField={'QuantityInvoice'}
                                width={130}
                                format={'#,##0'}
                                caption={Config.lang("SL_hoa_don")} />
                            <Column
                                dataField={'OTotalAmount'}
                                width={250}
                                format={'#,##0'}
                                caption={Config.lang("CRM_Tong_tien")}/>
                            <Column
                                dataField={'CTotalAmount'}
                                caption={Config.lang("CRM_Tong_tien_QD")}
                                format={'#,##0'}
                                width={200}/>
                            <Column
                                dataField={'CurrencyID'}
                                caption={Config.lang("CRM_Loai_tien")}
                                alignment={'center'}
                                width={150}/>
                            <Column
                                dataField={'ExchangeRate'}
                                caption={Config.lang("CRM_Ty_gia")}
                                width={100}/>
                        </GridContainer>}
                    </Form>
                </div>
        )
    }
}

export default connect(state => ({
        cbTypeTimeW19F1020: state.w19f1020.cbTypeTimeW19F1020,
        loadDataW19F1020: state.w19f1020.loadDataW19F1020,
    }),
    (dispatch) => ({
        w19f1020Actions: bindActionCreators(w19f1020Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W19F1020Page);